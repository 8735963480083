export const BREAKDOWN_TYPE = {
  BASIC: "basic",
  ADVANCED: "advanced"
} as const;

export const BREAKDOWN_PACKAGE_TYPE = {
  BREAKDOWN_SINGLE_GAME: "1-games",
  BREAKDOWN_FIVE_GAMES: "5-games",
  BREAKDOWN_TEN_GAMES: "10-games",
  BREAKDOWN_TWENTY_GAMES: "20-games"
} as const;
